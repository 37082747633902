import {
  PRICE_GTE, PRICE_LTE,
  PRICE_NEW_GTE, PRICE_NEW_LTE, PRICE_USED_GTE,
  PRICE_USED_LTE
} from "modules/store/scenes/ProductsList/constants/query-params";

export const CONSOLE = 'Console';
export const PC = 'PC';
export const HANDHELD_DEVICES = 'Handheld Devices';
export const KEYCHAINS = 'Keychains';

export const CATEGORIES_SHOW = ['Movies', 'Games', 'Toys', 'Posters', 'Music'];
export const CATEGORIES_ID_SHOW = [23, 32, 33, 34, 49]

export const QUERY_PARAM_PRICES = [
  PRICE_NEW_GTE,
  PRICE_USED_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_LTE,
  PRICE_GTE,
  PRICE_LTE
];

export const PRODUCT_PARENT_ID = 25;
