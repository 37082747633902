import React, {useEffect, useState} from "react"
import {Panel, PanelGroup} from "react-bootstrap"
import queryString from "query-string"
import classNames from "classnames/bind"
import {Link, useHistory} from "react-router-dom"
import vintageAxios from "services/api"
import _ from "lodash"
import {QUERY_PARAM_CATEGORIES, QUERY_PARAM_VIEW_TYPE} from "modules/store/scenes/ProductsList/constants/query-params"
import {
  COMING_SOON,
  COMING_SOON_SLUG,
  MOST_VIEWED,
  MOST_VIEWED_SLUG,
  NEW_THIS_WEEK,
  NEW_THIS_WEEK_SLUG,
  DEFAULT_QUERY_PARAMS
} from "modules/store/scenes/ProductsList/constants/product-categories"
import useQueryParams from "hooks/useQueryParams"

export default function SidebarCategories() {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState(null)
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState("")
  const queryParams = useQueryParams()

  const firstLinks = [
    {title: NEW_THIS_WEEK, slug: NEW_THIS_WEEK_SLUG},
    {title: MOST_VIEWED, slug: MOST_VIEWED_SLUG},
    // {title: COMING_SOON, slug: COMING_SOON_SLUG}
  ]

  /**
   * Fetch categories the first time
   */
  useEffect(() => {
    if (categories.length === 0)
      fetchCategories()
  }, [categories])

  /**
   * Fetch categories from the api
   */
  function fetchCategories() {
    const promise = vintageAxios.get('/products/categories/')
    promise.then(response => {
      let categories = response.data
      categories = _.filter(categories, category => category.parent !== null)
      // Products is the group with all the parent categories
      categories = _.filter(categories, category => category.parent.name !== "Products")
      categories = _.groupBy(categories, category => category.parent.name)
      setCategories(categories)
    })
    promise.catch(error => {

    })
  }

  /**
   * Handles click on the header of the panel
   * @param category {Object}
   */
  function handleHeaderClick(category) {
    setActiveKey(category.id)
    queryParams.update({
      [QUERY_PARAM_CATEGORIES]: category.id
    })
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  return (
      <React.Fragment>
        <div className="title">
          <h6>Categories</h6>
        </div>
        <div className="wrap">
          {
            firstLinks.map(item =>
                <div
                    key={item.slug}
                    className={classNames({
                      "item": true,
                      "active": queryParams.find(QUERY_PARAM_VIEW_TYPE) === item.slug
                    })}>
                  <Link to={{
                    pathname: "/store/products",
                    search: `?${queryString.stringify({
                      [QUERY_PARAM_VIEW_TYPE]: item.slug
                    })}`
                  }}>
                    {item.title}
                  </Link>
                </div>
            )
          }
          {
            Object.keys(categories).map(key => {
                  const arr = categories[key]
                  const category = arr[0].parent

                  return (
                      <div
                          className="item"
                          key={key}>
                        <PanelGroup
                            accordion
                            activeKey={activeKey}>
                          <Panel
                              eventKey={category.id}
                              header={
                                <span
                                    className={classNames({
                                      "active": queryParams.find(QUERY_PARAM_CATEGORIES) === category.id.toString()
                                    })}
                                    onClick={() => handleHeaderClick((category))}>
                                  {key}
                                  <i className="fa fa-caret-down"/>
                                </span>
                              }>
                            <ul>
                              {
                                arr.map(category =>
                                    <li
                                        key={category.id}
                                        className={classNames({
                                          "item": true,
                                          // If the query params in the category are part of the query params in the current location
                                          "active": queryParams.find(QUERY_PARAM_CATEGORIES) === category.id.toString()
                                        })}>
                                      <Link to={() => {
                                          const updatedQueryParams = queryParams.update({
                                            ...DEFAULT_QUERY_PARAMS,
                                            [QUERY_PARAM_CATEGORIES]: category.id,
                                          });
                                          return `/store/products${updatedQueryParams.asSearchString}`;
                                        }}
                                      >
                                        {category.name}
                                      </Link>
                                    </li>
                                )
                              }
                            </ul>
                          </Panel>
                        </PanelGroup>
                      </div>
                  )
                }
            )
          }
          <div className="item">
            <Link to="/gift-cards">
              Gift Cards
            </Link>
          </div>
        </div>
      </React.Fragment>
  )
}